//import { useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { InternalLink } from "../components/interactable/InternalLink"
import { Logo } from "../components/svg/Logo"
import { HiddenText } from "../components/text/HiddenText"
import { Title } from "../components/text/Title"
/* import { TitleMain } from "../components/text/TitleMain"
 */
import "./kampanje.css"

const Wrapper = styled.div``

const Kampanje = () => {
	//const data = useStaticQuery(query)

	const title = "Kampanje - Skullerud Sport Senter"
	//const { site, sanitySiteSettings } = data || {}

	//const { lang, author, description, siteUrl } = site?.siteMetadata || {}
	return (
		<Wrapper>
			<Helmet
				htmlAttributes={{
					lang: "nb-NO",
				}}
			>
				<meta charSet="utf-8" />
				<meta name="author" content="WAYS AS" /*content={author}*/ />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>
				<meta
					name="description"
					content="Prøv trening på VÅR måte helt gratis! Vi har lyst til å gi deg som enda ikke har vært på Skullerud Sport Senter en unik anledning til å prøve å trene hos oss i to uker, helt gratis og helt uforpliktende! Alt du trenger å gjøre er å legge inn navn og kontaktinformasjon, så hører &hellip;"
				/*content={
					meta?.description ||
					sanitySiteSettings?.meta?.description ||
					description}*/
				/>
				<title>
					Kampanje - Skullerud Sport Senter
					{/*meta?.title || sanitySiteSettings?.meta?.title || title*/}
				</title>
				<link
					href="https://fonts.googleapis.com/css?family=Montserrat:400,700"
					rel="stylesheet"
				/>
				<link
					type="text/plain"
					rel="author"
					href="https://THEME_DIR/humans.txt"
				/>
				<link
					rel="canonical"
					href="https://skullerudsportsenter.no/kampanje"
				/>

				<meta property="og:locale" content="nb_NO" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={title} />
				<meta
					property="og:description"
					content="Prøv trening på VÅR måte helt gratis! Vi har lyst til å gi deg som enda ikke har vært på Skullerud Sport Senter en unik anledning til å prøve å trene hos oss i to uker, helt gratis og helt uforpliktende! Alt du trenger å gjøre er å legge inn navn og kontaktinformasjon, så hører &hellip;"
				/>
				<meta
					property="og:url"
					content="https://kampanje.skullerudsportsenter.no/"
				/>
				<meta
					property="og:site_name"
					content="Skullerud Sport Senter"
				/>
				<meta name="twitter:card" content="summary" />
				<meta
					name="twitter:description"
					content="Prøv trening på VÅR måte helt gratis! Vi har lyst til å gi deg som enda ikke har vært på Skullerud Sport Senter en unik anledning til å prøve å trene hos oss i to uker, helt gratis og helt uforpliktende! Alt du trenger å gjøre er å legge inn navn og kontaktinformasjon, så hører [&hellip;]"
				/>
				<meta name="twitter:title" content={title} />
				<link
					rel="stylesheet"
					id="googleFont-css"
					href="//fonts.googleapis.com/css?family=Open+Sans%3A400%2C600%2C700&#038;ver=5.1.10"
					type="text/css"
					media="all"
				/>
				{/*
				<link
					rel="stylesheet"
					id="wp-block-library-css"
					href="https://kampanje.skullerudsportsenter.no/wp-includes/css/dist/block-library/style.min.css?ver=5.1.10"
					type="text/css"
					media="all"
				/>
				<link
					rel="stylesheet"
					id="wp-pagenavi-css"
					href="https://kampanje.skullerudsportsenter.no/wp-content/plugins/wp-pagenavi/pagenavi-css.css?ver=2.70"
					type="text/css"
					media="all"
				/>
				<link
					rel="stylesheet"
					id="theme-styles-updated-css"
					href="https://kampanje.skullerudsportsenter.no/wp-content/themes/skullerud-kampanje/assets/css/style2018.css?ver=5.1.10"
					type="text/css"
					media="all"
				/>*/}
			</Helmet>
			<header
				id="site-header"
				role="banner"
				className="o-header container-fluid"
			>
				<div className="container-fluid o-content-block">
					<div className="row">
						<div
							className="container top-background"
							style={{
								backgroundImage: "url(/kampanje/Vekt.png)",
							}}
						>
							<InternalLink to="/" className="mini-logo">
								<HiddenText>Hjem</HiddenText>
								<Logo width="100%" height="100%" />
							</InternalLink>
							<section id="top-content" className="m-page-title">
								<Title>
									<InternalLink
										to="/"
										className="home-link"
										noUnderline
									>
										Skullerud Sport Senter
									</InternalLink>
								</Title>
								<span className="a-sub-main-title">
									<p>
										Trening på <strong>vår</strong> måte
										siden 1980
									</p>
								</span>
							</section>
						</div>
					</div>
				</div>
			</header>
			<div className="container-fluid o-content-block ">
				<div className="container campaign-container m-auto">
					<section className="campaign">
						<img
							src="/kampanje/Bastian_Krogh_Heramb.jpg"
							alt="Bilde av Bastian"
							className="campaing-image"
						/>
					</section>
					<section className="campaign">
						<img
							src="/kampanje/Inger_Husby.jpg"
							alt="Bilde av Inger"
							className="campaing-image"
						/>
					</section>
					<section className="campaign">
						<img
							src="/kampanje/Lajla_Land.jpg"
							alt="Bilde av Lajla"
							className="campaing-image"
						/>
					</section>
					<section className="campaign">
						<img
							src="/kampanje/Silje_Sandvik.jpg"
							alt="Bilde av Silje"
							className="campaing-image"
						/>
					</section>
					<section className="campaign">
						<img
							src="/kampanje/Thomas_Axelson.jpg"
							alt="Bilde av Thomas"
							className="campaing-image"
						/>
					</section>
				</div>
			</div>
			<div className="container-fluid o-content-block --content-block-gray">
				<div className="row">
					<div className="container">
						<section id="form-content">
							<div className="clearfix o-form">
								<h2 className="a-form-title">
									Prøv trening på VÅR måte helt gratis!
								</h2>
								<p>
									Vi har lyst til å gi deg som enda ikke har
									vært på Skullerud Sport Senter en unik
									anledning til å prøve å trene hos oss i to
									uker, helt gratis og helt uforpliktende!
								</p>
								<p>
									Alt du trenger å gjøre er å legge inn navn
									og kontaktinformasjon, så hører du fra oss
									om kort tid for å avtale din gratis
									prøveperiode*.
								</p>

								<div
									className="gf_browser_gecko gform_wrapper"
									id="gform_wrapper_1"
								>
									<form
										method="post"
										id="gform_1"
										action="https://utils.wayscloud.no/skullerudcontact.php"
									>
										<input type='hidden' name='type' value='kampanje' />
										<div className="gform_body">
											<ul
												id="gform_fields_1"
												className="gform_fields top_label form_sublabel_below description_below"
											>
												<li
													id="field_1_1"
													className="gfield gfield_contains_required field_sublabel_below field_description_below"
												>
													<label
														className="gfield_label"
														htmlFor="name"
													>
														<HiddenText>
															Navn
														</HiddenText>
														<span className="gfield_required">
															*
														</span>
													</label>
													<div className="ginput_container ginput_container_text">
														<input
															name="name"
															id="name"
															type="text"
															defaultValue=""
															className="medium"
															placeholder="Navn"
														/>
													</div>
												</li>
												<li
													id="field_1_2"
													className="gfield gfield_contains_required field_sublabel_below field_description_below"
												>
													<label
														className="gfield_label"
														htmlFor="telephone"
													>
														<HiddenText>
															Telefonnummer
														</HiddenText>
														<span className="gfield_required">
															*
														</span>
													</label>
													<div className="ginput_container ginput_container_text">
														<input
															name="phone"
															id="telephone"
															type="text"
															defaultValue=""
															className="medium"
															placeholder="Telefonnummer"
														/>
													</div>
												</li>
												<li
													id="field_1_3"
													className="gfield gfield_contains_required field_sublabel_below field_description_below"
												>
													<label
														className="gfield_label"
														htmlFor="email"
													>
														<HiddenText>
															E-post
														</HiddenText>
														<span className="gfield_required">
															*
														</span>
													</label>
													<div className="ginput_container ginput_container_email">
														<input
															name="email"
															id="email"
															type="text"
															defaultValue=""
															className="medium"
															placeholder="E-postadresse"
														/>
													</div>
												</li>
											</ul>
										</div>
										<div className="gform_footer top_label">
											<input
												type="submit"
												id="gform_submit_button_1"
												className="gform_button button"
												defaultValue="Få 2 uker gratis trening"
											/>
										</div>
									</form>
								</div>
								<div className="a-disclaimer">
									*Tilbudet gjelder kun for deg som ikke
									allerede er medlem ved Skullerud Sport
									Senter.
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>

			<div className="container-fluid o-content-block o-content-block--extra-padding">
				<div className="row">
					<div className="container flex-center">
						<h2>Hvorfor trene hos oss?</h2>
						<p style={{ textAlign: "left" }}>
							<strong>
								Som medlem ved Skullerud Sport Senter får du:
							</strong>
						</p>
						<ul>
							<li style={{ textAlign: "left" }}>
								Et personlig tilpasset treningsprogram der du
								sammen med en trener setter mål for din trening,
								og får jevnlige oppfølging.
							</li>
							<li style={{ textAlign: "left" }}>
								Trene i store luftige lokaler eller på apparater
								ute ved fint vær!
							</li>
							<li style={{ textAlign: "left" }}>
								Velge mellom et stort utvalg av gruppetimer og
								instruktører med lang erfaring og utdannelse.
							</li>
							<li style={{ textAlign: "left" }}>
								Full oversikt over din trening, din progresjon
								og dine måloppnåelser med en enkel app.
							</li>
							<li style={{ textAlign: "left" }}>
								Vi bryr oss om våre medlemmer og har egne timer
								tilpasset godt voksne, rehabilitering, og
								opptrening etter skader.
							</li>
							<li>Gratis parkering utenfor.</li>
						</ul>
						<h3 style={{ textAlign: "center" }}>
							<strong>
								Tren med medlemmer fra hele Oslo på Skullerud
								Sport Senter!
							</strong>
						</h3>
					</div>
				</div>
			</div>

			<div className="container-fluid o-content-block --content-block-gray">
				<div className="row">
					<div className="container">
						<section id="info-content">
							<div className="clearfix">
								<h2 className="a-block-title">
									Vi hjelper deg gjennom hele året
								</h2>
								<ul className="o-icon-list">
									<li className="m-icon-line clearfix">
										<a
											href="https://www.skullerudsportsenter.no/yoga"
											className="a-icon-link"
										>
											<div className="a-icon">
												<img
													src="/kampanje/yoga-1.png"
													alt="Ikon av en person i en yogastilling"
												/>
											</div>
											<div className="a-icon-text">
												<span>YOGA</span>
											</div>
										</a>
									</li>

									<li className="m-icon-line clearfix">
										<a
											href="https://www.skullerudsportsenter.no/gruppe-trening#WattSykling"
											className="a-icon-link"
										>
											<div className="a-icon">
												<img
													src="/kampanje/sykling-1.png"
													alt="Ikon av en person på ergometersykkel"
												/>
											</div>
											<div className="a-icon-text">
												<span>SYKLING</span>
											</div>
										</a>
									</li>

									<li className="m-icon-line clearfix">
										<a
											href="https://www.skullerudsportsenter.no/gruppe-trening#Romaskin"
											className="a-icon-link"
										>
											<div className="a-icon">
												<img
													src="/kampanje/roing-1.png"
													alt="Ikon av en person i en romaskin"
												/>
											</div>
											<div className="a-icon-text">
												<span>ROING</span>
											</div>
										</a>
									</li>

									<li className="m-icon-line clearfix">
										<a
											href="https://www.skullerudsportsenter.no/gruppe-trening"
											className="a-icon-link"
										>
											<div className="a-icon">
												<img
													src="/kampanje/gruppe-1.png"
													alt="Ikon av en tre personer som strekker seg"
												/>
											</div>
											<div className="a-icon-text">
												<span>GRUPPETRENING</span>
											</div>
										</a>
									</li>

									<li className="m-icon-line clearfix">
										<a
											href="https://www.skullerudsportsenter.no/stryketrening"
											className="a-icon-link"
										>
											<div className="a-icon">
												<img
													src="/kampanje/styrke-1.png"
													alt="Ikon av en person som løfter vekter"
												/>
											</div>
											<div className="a-icon-text">
												<span>STYRKETRENING</span>
											</div>
										</a>
									</li>
								</ul>
							</div>
						</section>
					</div>
				</div>
			</div>
		</Wrapper>
	)
}

/*const query = graphql`
	query {
		
	}
`*/

export default Kampanje
